import React, { useState } from "react";
import 'react-notifications-component/dist/theme.css';
import AssembleTeam from './components/AssembleTeam/AssembleTeam';
import DisplayCards from './components/DisplayCards/DisplayCards';
import LeftImages from './components/ImageGroups/LeftImages';
import RightImages from './components/ImageGroups/RightImages';
import './global.css';
import './landing.css';
import './mobile.css'; // Import mobile-specific styles
import { PopUp } from "./popUp";
import YellowButton from "./yellowButton";
import ImageCluster from "./components/ImageCluster/ImageCluster";
import { isMobile } from 'react-device-detect'; // npm install react-device-detect

function App() {
  const [popUp, setPopUp] = useState<boolean>(false);

  const handleButtonClick = () => {
    setPopUp(true);
  };

  return (
      <div className="LandingPage">
        <div className="NavBar">
          <div>
            <img src='./NextArena/Logo.svg' style={{ height: "1.75rem" }} />
            <img src='./NextArena/NextArena.svg' style={{ height: "1.75rem", marginLeft: "0.5rem" }} />
          </div>
          <YellowButton props={{ text: "Join the Waitlist", onClick: handleButtonClick}} />
        </div>
        <div className="Title">
          <h1>ESPORTS <span>TOURNAMENTS</span> FOR EVERYONE</h1>
        </div>
        <div className="ImagePane">
          <LeftImages />
          <RightImages />
        </div>
        <div className="AssembleTeam">
          <AssembleTeam />
          <div className="Buttons">
            <YellowButton props={{ text: "Join the Waitlist", onClick: handleButtonClick }} />
          </div>
        </div>
        <div className="ImageCluster">
          <ImageCluster />
        </div>
        <div className="DisplayCards">
          <DisplayCards />
        </div>
        {<PopUp setPopUp={setPopUp} show={popUp}/>}
      </div>
  );
}

export default App;
