export async function register(email:string, description:string):Promise<{status: string, waitlist_position?:number}> {
    const endpt = 'https://api.nextarena.gg/register/';
    const body = {email: email, description: description};
    try {
        const response = await fetch(endpt, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        });
        const data = await response.json();
        return data
 
      } catch (error) {
        return {status: "error"};
      }
}
