import React from 'react';
import './LeftImages.css';

const LeftImages: React.FC = () => {
  return (
    <div>
      <img src="/images/bigblue.png" className="large-imageL" alt="Big Blue" />
      {/* <video autoPlay loop id="background-video" className="large-imageL">
        <source src="/videos/repeat-gaming.webm" type="video/webm"></source>
      </video> */}
      <div className="s-images">
        <img src="/images/blue1.png" className="small-image" alt="Blue 1" />
        <img src="/images/blue2.png" className="small-image" alt="Blue 2" />
        <img src="/images/blue3.png" className="small-image" alt="Blue 3" />
        <img src="/images/blue4.png" className="small-image" alt="Blue 4" />
      </div>

    </div>
  );
};

export default LeftImages;