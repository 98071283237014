import React, { useEffect, useState, useRef } from "react";
import validator from 'validator';
import { register } from "./api_calls/register_calls";
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import styles from './app.module.css';
import Modal from "react-bootstrap/Modal";
import emailjs from 'emailjs-com';

interface PopUpInterface {
  show: boolean,
  setPopUp: React.Dispatch<React.SetStateAction<boolean>>,
}

export function PopUp({ show, setPopUp }: PopUpInterface) {
  const [step, setStep] = useState(1);
  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [attemptSubmit, setAttemptSubmit] = useState(false);
  const inviteLinkRef = useRef<HTMLInputElement>(null);
  const [waitlistPosition, setWaitlistPosition] = useState<number | null>(null);

  // const EMAILJS_SERVICE_ID = 'XXX';
  // const EMAILJS_TEMPLATE_ID = 'XXX';
  // const EMAILJS_PUBLIC_KEY = 'XXX';

  const notif = (success: boolean, message: string) => {
    Store.addNotification({
      title: success ? "Success" : "Uh-oh",
      message: message,
      type: success ? "success" : "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  };

  const copyToClipboard = () => {
    const inviteLink = inviteLinkRef.current?.value;
    if (inviteLink) {
      navigator.clipboard.writeText(inviteLink).then(() => {
        notif(true, "Copied to clipboard");
      }, (err) => {
        notif(false, "Failed to copy to clipboard");
      });
    }
  };

  // const sendEmails = () => {
  //   const userTemplateParams = {
  //     to_email: email,
  //     message: 'Thank you for joining the waitlist!',
  //   };
  
  //   const adminTemplateParams = {
  //     to_email: 'admin@nextarena.gg',
  //     message: `New user joined the waitlist: ${email}`,
  //   };
  
  //   // Send email to the user
  //   emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, userTemplateParams, EMAILJS_PUBLIC_KEY)
  //     .then((response) => {
  //       console.log('User email sent successfully:', response.status, response.text);
  //       notif(true, "User email confirmation sent successfully");
  //     }, (err) => {
  //       console.error('Failed to send user email:', err);
  //       notif(false, "Failed to send user email confirmation");
  //     });
  
  //   // Send email to the admin
  //   emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, adminTemplateParams, EMAILJS_PUBLIC_KEY)
  //     .then((response) => {
  //       console.log('Admin email sent successfully:', response.status, response.text);
  //     }, (err) => {
  //       console.error('Failed to send admin email:', err);
  //     });
  // };
  

  useEffect(() => {
    if (validator.isEmail(email) || !attemptSubmit) {
      setEmailError('');
    } else {
      setEmailError('Enter valid Email!');
    }

    if (description === "" && attemptSubmit) setDescriptionError("Select Description")
    else setDescriptionError("");
  }, [email, description, attemptSubmit]);

  const validateEmail = (e: any) => {
    setEmail(e.target.value);
  }

  const handleChange = (e: any) => {
    setDescription(e.target.value);
  }

  const handleSubmit = async () => {
    setAttemptSubmit(true);

    if (validator.isEmail(email) && description !== "") {
      const response = await register(email, description);
      if (response.status === "success" || response.status === "already waitlisted") {
        setWaitlistPosition(response.waitlist_position || null);
        setStep(2); // Move to the next step of popUp
        if (response.status === "success"){
          // sendEmails(); // Send emails to newly registered users
          // notif(true, "Your email is registered");
        } 
        else if (response.status === "already waitlisted") {
          // notif(false, "You are already waitlisted");
        }
      }
      else {
        // notif(false, "Your email has not been registered");
      }
    }
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className={styles.modal}>
            <div className={styles.iconWrapper}>
              <div className={styles.icon}>
                <div className={styles.default}>
                  <div className={styles.iconName}>hourglass_bottom</div>
                </div>
              </div>
            </div>
            <div className={styles.joinTheWaitlistParent}>
              <div className={styles.joinTheWaitlist}>Join The Waitlist</div>
              <div className={styles.signUpForContainer}>
                <p className={styles.signUpFor}>Sign up for the platform and get early access and</p>
                <p className={styles.signUpFor}>free coins!</p>
              </div>
            </div>
            <div className={styles.formFields}>
              <div className={styles.input}>
                <div className={styles.labelContainer}>
                  <div className={styles.label}>Email</div>
                </div>
                <div className={styles.inputContainer}>
                  <div className={styles.icon1}>
                    <div className={styles.default}>
                      <div className={styles.iconName1}>mail</div>
                    </div>
                  </div>
                  <input className={styles.textInput} type="text" id="userEmail" onChange={(e) => validateEmail(e)} placeholder='abc@nextarena.gg'></input>
                </div>
                <div className={styles.text} style={{ fontWeight: 'bold', color: 'red' }}>{emailError}</div>
              </div>
              <div className={styles.input}>
                <div className={styles.labelContainer}>
                  <div className={styles.label}>Your Role</div>
                </div>
                <div className={styles.inputContainer1}>
                  <div className={styles.textInput}>
                    <select className={styles.textInput1} onChange={handleChange}>
                      <option value="" disabled selected hidden>What describes you the best?</option>
                      <option value="Casual Gamer">Casual Gamer</option>
                      <option value="Competitive Gamer">Competitive Gamer</option>
                      <option value="Professional E-Sports Player">Professional E-Sports Player</option>
                      <option value="Streamer/Content Creator">Streamer/Content Creator</option>
                      <option value="Enthusiast">Enthusiast</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className={styles.text} style={{ fontWeight: 'bold', color: 'red' }}>{descriptionError}</div>
              </div>
            </div>
            <div className={styles.button} onClick={handleSubmit}>
              <div className={styles.icon1}>
                <div className={styles.default}>
                  <div className={styles.iconName3}>hotel_class</div>
                </div>
              </div>
              <div className={styles.button1}>Join The Waitlist</div>
            </div>
            <div className={styles.button2} onClick={() => setPopUp(false)}>
              <div className={styles.icon4}>
                <div className={styles.default}>
                  <div className={styles.iconName4}>close</div>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className={styles.modal}>
            <div className={styles.iconWrapper}>
              <div className={styles.icon}>
                <div className={styles.default}>
                  <div className={styles.iconName}>move_up</div>
                </div>
              </div>
            </div>
            <div className={styles.joinTheWaitlistParent}>
              <div className={styles.joinTheWaitlist}>{waitlistPosition === -1 ? "Already Added To The Waitlist!" : "Added To The Waitlist!"}</div>
              <div className={styles.signUpForContainer}>
                <p className={styles.signUpFor}>{waitlistPosition === -1 ? "You have already been added to our waitlist. We'll be in touch soon! Until then, invite friends to join you!" : "You have been added to our waitlist. We’ll be in touch soon! Until then, invite friends to join you!"}</p>
              </div>
            </div>
            <div className={styles.modalPosition}>
            <div className={styles.number}>{waitlistPosition === -1 ? ":)" : waitlistPosition !== null ? `#${waitlistPosition}` : "Already Waitlisted"}</div>
            <div className={styles.currentPosition}>{waitlistPosition === -1 ? "" : "Current position"}</div>
            </div>
            <div className={styles.formFields}>
                <div className={styles.inputParent}>
                    <div className={styles.inputTwo}>
                        <div className={styles.inputContainerTwo}>
                        <input className={styles.textTwo} type="text" value="https://discord.gg/RcUhURh246" readOnly ref={inviteLinkRef} />
                        </div>
                    </div>
                    <button className={styles.copyButton} onClick={copyToClipboard}>
                    <div className={styles.iconTwo}>
                        <div className={styles.default}>
                        <div className={styles.iconNameTwo}>content_copy</div>
                        </div>
                    </div>
                    </button>
                </div>
            </div>
            <div className={styles.discordButton} onClick={() => { 
                setPopUp(false); 
                setStep(1); 
                window.location.href = 'https://discord.gg/RcUhURh246'; //our disc link
            }}>
                <img src="/discord.svg" alt="Discord Icon" className={styles.customIcon} />
                <div className={styles.discordButton1}>Join Our Discord</div>
            </div>
            <div className={styles.button2} onClick={() => setPopUp(false)}>
              <div className={styles.icon4}>
                <div className={styles.default}>
                  <div className={styles.iconName4}>close</div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <Modal show={show} onHide={() => setPopUp(!show)} centered>
      {renderStep()}
    </Modal>
  );
}
