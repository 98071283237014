import React from 'react';
import YellowButton from '../../yellowButton';
import './AssembleTeam.css';

const AssembleTeam: React.FC = () => {
  return (
    <div className="AssembleTeam">
      <h2>ASSEMBLE YOUR DREAM ESPORTS TEAM</h2>
      <p>Join an existing team or create your own to battle others professionally.</p>
    </div>
  );
};

export default AssembleTeam;
