import React from 'react';
import './RightImages.css';

const RightImages: React.FC = () => {
  return (
    <div className="All-right">
      <img src="/images/bigred.png" className="large-imageR" alt="Big Red" />
      <div className="r-images">
        <img src="/images/red1.png" className="small-image-r" alt="Red 1" />
        <img src="/images/red2.png" className="small-image-r" alt="Red 2" />
        <img src="/images/red3.png" className="small-image-r" alt="Red 3" />
        <img src="/images/red4.png" className="small-image-r" alt="Red 4" />
      </div>
    </div>
  );
};

export default RightImages;
