import {Button} from 'react-bootstrap';
import "./yellowbutton.css"

interface YellowButtonRightProps {
    text:string;
    icon?:JSX.Element;
    width?:string;
    padding?:string;
    type?: "button" | "submit" | "reset";
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}
  

const YellowButton: React.FC<{props:YellowButtonRightProps}> = ({props}) =>{
    const {text, icon, width,type,padding, onClick} = props;
    const handleClick: React.MouseEventHandler<HTMLButtonElement> | undefined = onClick;

    return(
        <Button className="yellow_button" style={{ width: width, padding: padding,}} variant="primary" type={type} onClick={handleClick}>
            <div className="content">
                <span className="text">{text}</span>
                <span className="material-icons arrowicon">arrow_forward</span>
            </div>
        </Button>
    )
}

export default YellowButton;