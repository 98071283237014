import React from 'react';
import './DisplayCards.css';

const DisplayCards: React.FC = () => {
  return (
    <div className="DisplayCards">
      <h2>COMPETE FOR <span>HIGH</span> STAKES</h2>
      <div className="CardContainer">
        <div className="Card">
          <div className="fonts">$ - $$$</div>
          <p>Buy-in at any range. Bigger buy-in, bigger prize.</p>
        </div>
        <div className="Card">
          <div className="fonts">5v5</div>
          <p>Play with a team or join rapid or solo games.</p>
        </div>
        <div className="Card">
          <div className="fonts">24/7</div>
          <p>Play anytime with tournament lobbies 24/7.</p>
        </div>
      </div>
    </div>
  );
};

export default DisplayCards;
