import React from 'react';
import './ImageCluster.css';

const ImageCluster: React.FC = () => {
    const imagesRow1 = [
        './imageCluster/row1/image_11.png',
        './imageCluster/row1/image_13.png',
        './imageCluster/row1/image_14.png',
        './imageCluster/row1/image_15.png',
        './imageCluster/row1/image_16.png',
        './imageCluster/row1/image_17.png',
        './imageCluster/row1/image_18.png',
        './imageCluster/row1/image_19.png',
        './imageCluster/row1/image_20.png',
    ];

    const imagesRow2 = [
    './imageCluster/row2/image_11.png',
    './imageCluster/row2/image_13.png',
    './imageCluster/row2/image_14.png',
    './imageCluster/row2/image_15.png',
    './imageCluster/row2/image_16.png',
    './imageCluster/row2/image_17.png',
    './imageCluster/row2/image_18.png',
    './imageCluster/row2/image_19.png',
    './imageCluster/row2/image_20.png',
    ];
    return (
        <div className="Pane">
        <div className="slider">
            <div className="slide-track">
                {imagesRow1.concat(imagesRow1).map((src, index) => (
                    <div className="slide" key={index}>
                        <img src={src}/>
                    </div>
                ))}
            </div>
        </div>
        <div className="slider reverse">
            <div className="slide-track">
                {imagesRow2.concat(imagesRow2).map((src, index) => (
                    <div className="slide" key={index}>
                        <img src={src}/>
                    </div>
                ))}
            </div>
        </div>
    </div>
    );
};

export default ImageCluster;